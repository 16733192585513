import { Box, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import Table from '@mui/material/Table';
const NormalTable = () => {
  return (
    <div>
      <Box sx={{ padding:"24px", width:"100%"}}>
        <Typography
          variant="h6"
          align="left"
          sx={{
            mb: 1,
            fontWeight: "700",
            fontSize: "14px",
            fontFamily: "Metropolis",
          }}
        >
          Terms
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "12px",
            border: "1px solid #000",
            boxShadow: "0 0px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
        <TableContainer component={Paper}>
      <Table> 
        <TableHead>
          <TableRow>
            <TableCell style={{borderRight:"1px solid #000", padding:"12px", borderBottom:"1px solid #000", fontWeight:"700", letterSpacing:"0", fontSize:'12px',fontFamily:'Metropolis',  }}>Calories</TableCell>
            <TableCell align="left" style={{borderBottom:"1px solid #000", padding:"12px", fontWeight:"700", letterSpacing:"0", fontSize:'12px',fontFamily:'Metropolis', }}>Calories</TableCell>
     
          </TableRow>
        </TableHead>
        <TableBody>      
            <TableRow >       
              <TableCell align="left"  style={{borderRight:"1px solid #000",}}>1</TableCell>
              <TableCell align="left">2</TableCell>
       
            </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
        </TableContainer>
      </Box>
    </div>
  );
};

export default NormalTable;
