import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import background from "../../images/diwali/playpoint-bg.png";
import playpointimg from "../../images/diwali/new/discover.png";
import playpointimg2 from "../../images/diwali/new/shop.png";
import playpointimg3 from "../../images/diwali/playpointimg3.png";
import playpointimg4 from "../../images/diwali/new/archive.png";
import playpointimg5 from "../../images/diwali/playpointimg5.png";
import playpointimg6 from "../../images/diwali/new/make.png";
import playGameBtn from "../../images/diwali/playgame-button.png";
import visit from "../../images/diwali/new/Frame 1321316289.png";



const data = [
  {
    imgSrc: playpointimg,
    title: "Discover brands",
    description: "Get 10 points for every unique brand you visit via CashKaro",
    buttonImg: playGameBtn,
    link: "/diwali-league/discover-brand",
  },
  {
    imgSrc: playpointimg2,
    title: "Shop and Collect",
    description: "Get upto 400 points on every shopping via CashKaro",
    buttonImg: playGameBtn,
    link: "/diwali-league/shop-collect",
  },
  {
    imgSrc: playpointimg3,
    title: "Shop from Today’s Featured  Brand",
    description: "Don't miss out! Shop from our brand of the day & get 300 points",
    buttonImg: playGameBtn,
    link: "/diwali-league/shop-brand",
  },
  {
    imgSrc: playpointimg4,
    title: "Achieve point milestones",
    description: "Unlock extra points. Get up to 1000 bonus points for reaching your milestone",
    buttonImg: playGameBtn,
    link: "/diwali-league/achive-points",
  },
  {
    imgSrc: playpointimg5,
    title: "Maintain Shopping Streak",
    description: "Shop daily for 2, 3, or 5 days in a row and get up to 1000 points",
    buttonImg: playGameBtn,
    link: "/diwali-league/maintain-shop",
  },
  {
    imgSrc: playpointimg6,
    title: "Higher the bill better the points",
    description: "Spend big, get big! Get up to 200 points when you spend ₹5,000, ₹7,500, or ₹10,000 in a day.",
    buttonImg: playGameBtn,
    link: "/diwali-league/higher",
  },
];

export default function PlayPoints() {
  const navigate = useNavigate()
  const segment = localStorage.getItem('segment')

  return (
    <Grid container sx={{ background: "#fff" }}>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Grid
          sx={{
            background: "#1F162E",
            width: "100%",
          }}
        >
          <Grid pb={6} pt={3} pl={2} pr={2} item sx={{
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
              backgroundPosition: "0 -20px",
              backgroundRepeat: "no-repeat",
              width: "100%",
              position: "relative",
            }}
          >
            <Box
              sx={{
                borderRadius: "312px",
                background: "#7044BA",
                filter: "blur(107px)",
                width: "312px",
                height: "166px",
                position: "absolute",
                top: "-130px",
                left: 0,
                right: 0,
                margin: "0 auto",
                zIndex: 1,
              }}
            ></Box>
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: '"Montserrat Alternates"',
                fontSize: "22px",
                fontWeight: 900,
                lineHeight: "120%",
                padding: "0 10px",
                maxWidth: "340px",
                margin: "0 auto",
                position: "relative",
                zIndex: 22,
              }}
            >
              Play the games & start collecting points
            </Typography>
          </Grid>
          <Grid item sx={12} pl={2} pr={2}>
         {segment != 1 &&  <Grid  pb={2} sx={12}>
                <Box
                  sx={{
                    borderRadius: "16px",
                    border: "1px solid #452E6B",
                    background: "linear-gradient(180deg, #412C62 0%, #220F41 100%)",
                    padding: "16px",
                  }}
                >
                  <Box sx={{ width: "100%", marginBottom: "12px" }}>
                    <img src={visit} style={{ width: "100%" }} alt="Play Point" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <Box sx={{ width: "70%" }}>
                      <Typography
                        sx={{
                          color: "#FFF",
                          fontFamily: "Metropolis",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "140%",
                          textTransform: "capitalize",
                          marginBottom: "4px",
                          whiteSpace:"nowrap"
                        }}
                      >
                        Visit And Earn
                      </Typography>
                      <Typography
                        sx={{
                          color: "#E9E9E9",
                          fontFamily: "Metropolis",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "140%",
                        }}
                      >
                        Visit brands daily for 5 days
                        in a row and get up to 100 points
                      </Typography>
                    </Box>
                    <Box style={{ position: "relative" }}>
                      <Box
                        style={{
                          borderRadius: "4.665px",
                          background: "#FFF",
                          filter: "blur(8px)",
                          display: "inline-flex",
                          height: "27.987px",
                          padding: "9.329px",
                          position: "absolute",
                          top: "12px",
                          left: "0",
                          width: "70px",
                          right: "0",
                          margin:"0 auto"
                        }}
                      ></Box>
                      <Button
                        onClick={() => navigate('/diwali-league/visit-earn')}
                        style={{
                          display: "inline-flex",
                          height: "33px",
                          minWidth: "80px",
                          padding: "11px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5.5px",
                          flexShrink: 0,
                          borderRadius: "5.5px",
                          background: "#FFF",
                          color: "#231043",
                          textAlign: "center",
                          fontFamily: "Metropolis",
                          fontSize: "11px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "normal",
                          marginTop: "10px",
                          letterSpacing: "0",
                          textTransform: "capitalize"
                        }}
                      >
                        Play Game
                      </Button>
                    </Box>

                  </Box>
                </Box>
              </Grid>}

            {data.map((item, index) => (
              <Grid key={index} pb={2} sx={12}>
                <Box
                  sx={{
                    borderRadius: "16px",
                    border: "1px solid #452E6B",
                    background: "linear-gradient(180deg, #412C62 0%, #220F41 100%)",
                    padding: "16px",
                  }}
                >
                  <Box sx={{ width: "100%", marginBottom: "12px" }}>
                    <img src={item.imgSrc} style={{ width: "100%" }} alt="Play Point" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <Box sx={{ width: "70%" }}>
                      <Typography
                        sx={{
                          color: "#FFF",
                          fontFamily: "Metropolis",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "140%",
                          textTransform: "capitalize",
                          marginBottom: "4px",
                          whiteSpace:"nowrap"
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#E9E9E9",
                          fontFamily: "Metropolis",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "140%",
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                    <Box style={{ position: "relative" }}>
                      <Box
                        style={{
                          borderRadius: "4.665px",
                          background: "#FFF",
                          filter: "blur(8px)",
                          display: "inline-flex",
                          height: "27.987px",
                          padding: "9.329px",
                          position: "absolute",
                          top: "12px",
                          left: "0",
                          width: "70px",
                          right: "0",
                          margin:"0 auto"
                        }}
                      ></Box>
                      <Button
                        onClick={() => navigate(item?.link)}
                        style={{
                          display: "inline-flex",
                          height: "33px",
                          minWidth: "80px",
                          padding: "11px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5.5px",
                          flexShrink: 0,
                          borderRadius: "5.5px",
                          background: "#FFF",
                          color: "#231043",
                          textAlign: "center",
                          fontFamily: "Metropolis",
                          fontSize: "11px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "normal",
                          marginTop: "10px",
                          letterSpacing: "0",
                          textTransform: "capitalize"
                        }}
                      >
                        Play Game
                      </Button>
                    </Box>

                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
    </Grid>
  );
}
