import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Slider, Button, styled } from "@mui/material";

import achivepointsimg from "../../images/diwali/visitearnbg.png";
import thumbimg from "../../images/diwali/thumbimg.png";
import points30bg from "../../images/diwali/points30bg.png";
import ButtonComponent from "../../components/diwali/buttonBottom";
import samsung from "../../images/diwali/samsung2.png";
// import clove from "../../images/diwali/c"
import booking from "../../images/diwali/booking2.png";
import boat from "../../images/diwali/boat2.png";
import trumeds from "../../images/diwali/trumeds2.png";
import adidas from "../../images/diwali/adidas2.png";
import visitprogress from "../../images/diwali/visitprogress.png";
import Clove from "../../images/diwali/new/clove.png";
import Ashp from "../../images/diwali/new/ashp.png";
import Ryze from "../../images/diwali/new/ryze.png";
import  Strech from "../../images/diwali/new/strch.png";
import Ivedic from "../../images/diwali/new/ivedic.png";

const VerticalSlider = styled(Slider)({
  height: 400,
});

const marks = [
  { value: 0 },
  { value: 1, label: "+ 30 points" },
  { value: 2, label: "+ 30 points" },
  { value: 3, label: "+ 30 points" },
  { value: 4, label: "+ 30 points" },
  { value: 5, label: "+ 30 points" },
  { value: 6, label: "+ 30 points" },
];


export default function VisitAndEarn() {
  const [sliderValue, setSliderValue] = useState(0);
  const [marks2, setMarks2] = useState([]);
  const [marks3] = useState(["Day 0","Day 1", "Day 2", "Day 3", "Day 4", "Day 5"]);
  const segment = localStorage.getItem('segment')
  const [activeLink, setActiveLink] = useState(null);
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  const data = [
    {
      imgSrc: Clove,
      title: "Clove",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/clove-oral-care-coupons"
        : "cashkaro://stores/clove-oral-care-coupons",
    },
    {
      imgSrc: Ryze,
      title: "WeRyze",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/ryze-coupons"
        : "cashkaro://stores/ryze-coupons",
    },
    {
      imgSrc: Ivedic,
      title: "Ivedic",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/ivedic-coupon-codes"
        : "cashkaro://stores/ivedic-coupon-codes",
    },
    {
      imgSrc: Strech,
      title: "Strch",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/strch-coupons"
        : "cashkaro://stores/strch-coupons",
    },
    {
      imgSrc: Ashp,
      title: "Ashpveda",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/ashpveda-coupon-codes"
        : "cashkaro://stores/ashpveda-coupon-codes",
    },
    
   
  ];
  useEffect(() => {
    // Retrieve the milestone values from localStorage
    const retrievedMarks = [];
    for (let i = 1; i <= 7; i++) {
      const milestoneValue = localStorage.getItem(`milestone_${i}`) || 0;
      retrievedMarks.push({ value: Number(i*10), label: `${10} Points` });
    }
    // Add the 8th value as 0 by default
    retrievedMarks.unshift({ value: 0,});

    
    setMarks2(retrievedMarks);
  }, []);


  const bottomValues = [
    '39.2857%', // 1st div
    '49%',      // 2nd div
    '63.2857%', // 3rd div
    '74%',      // 4th div
    '99%'      // 5th div
];


  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };
console.log(marks2, "marks2")


  return (
    <Grid container sx={{ background: "#fff" }} >
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} sx={{ background: "#1F162E" }}>
        <Grid
          sx={{
            background: "#1F162E",
            width: "100%",
            paddingBottom: "27%",
          }}
        >
          <Grid pb={2}>
            <img src={achivepointsimg} style={{ width: "100%" }} />
          </Grid>
          <Grid item sx={12} pr={3} pl={3} pb={2}>
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Metropolis",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "140%",
                textTransform: "capitalize",
                marginBottom: "8px",
              }}
            >
              Visit And Earn
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Metropolis",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "140%",
                color: "#fff",
              }}
            >
            Visit brands daily for 5 days <br/>
            in a row and get up to 100 points
            </Typography>
          </Grid>

          <Grid
            container
            spacing={0}
            sx={{ justifyContent: "center" }}
            pb={2}
            pl={3}
            pr={3}
          >
            {data.map((item, index) => (
              <Grid item xs={4} key={index}>
                <a
                  href={item.link}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  onClick={() => handleLinkClick(item.link)}
                >
                  <img src={item.imgSrc} style={{ width: "100%",padding:'18px' }} />
                  <Typography
                    sx={{
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Metropolis",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "120%",
                      position: "relative",
                      top: "-10px",
                      textDecoration: "none",
                    }}
                  >
                    {item.title}
                  </Typography>
                </a>
              </Grid>
            ))}
          </Grid>
          <Grid sx={12} pl={6} pr={6} pb={2.5}>
            <img src={visitprogress} style={{ width: "100%" }} />
          </Grid>

<Typography
            sx={{
              
              color: Number(localStorage.getItem('savings_points')) >= localStorage.getItem('milestone_7') ? "#FE8B37" : "#7524CE",
              textAlign: "center",
              fontFamily: "Metropolis",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "10px",
              paddingBottom: "32px",
              paddingTop: "0px",
            }}
          >
          + 50 Bonus points
          </Typography> 

           <Grid item sx={{ height: "368px", boxSizing: "border-box", marginBottom: "32px" }}>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                height: "368px",
                boxSizing: "border-box",
                left: "-20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  height: "368px",
                  zIndex: "11",
                  boxSizing: "border-box",
                  position:"relative"
                }}
              >
               
                <VerticalSlider
                  aria-label="Temperature"                  
                  defaultValue={localStorage.getItem("savings_visit_earn") || 0}
                  value={localStorage.getItem("savings_visit_earn") || 0}                
                  onChange={handleSliderChange}
                  valueLabelDisplay="off"               
                  marks={marks2.map((mark) => {                    
                    return {
                      value: mark.value,
                      label: mark.label,
                    }
                  })}
                  orientation="vertical"
                  min={0}
                  // step={100}
                  max={50}
                  // max={marks2[marks2.length-1]?.value}
                  sx={{
                    color: "transparent",
                    boxShadow: "0px 0px 0px",
                    borderRadius: "20px",
                    zIndex: "222",
                    position: "relative",
                    height: "368px",     
                    position:"relative",
                    left:"-50px" ,              
                    boxSizing: "border-box",
                    '&.Mui-disabled': {
                      display:"none",
                      color: '#E85F34', // Custom color for disabled state
                    },
                    "& .MuiSlider-thumb": {
                      height: 26,
                      width: 37,
                      boxShadow: "inherit !important",
                      background: `url(${thumbimg})`,
                      display:"none",
                      border: "0px solid currentColor",
                      "&:focus, &:hover, &.Mui-active": {
                        boxShadow: "inherit",
                      },
                      ":before": {
                        boxShadow: "0px 0px 0px",
                      },
                    },
                    "& .MuiSlider-track": {
                      width: 6,
                    },
                    "& .MuiSlider-rail": {
                      backgroundColor: "#36276A",
                      opacity: "1",
                      width: 6,
                      display:"none"
                    },
                    "& .MuiSlider-mark": {
                      backgroundColor: "#36276A",
                      height: 2,
                       display:"none",
                      width: 14,
                      
                      borderRadius: "20px",
                      "&.MuiSlider-markActive": {
                        backgroundColor: "transparent",
                      },
                    },
                    "& .MuiSlider-markLabel": {
                      color: "#fff",
                      textAlign: "center",
                      fontFamily: "Metropolis",
                      fontSize: "10px",
                      fontStyle: "normal",   
                      lineHeight: "10px",
                      fontWeight:"400",
                      marginLeft: "10px",
                    },
      
                  }}
                />
                <VerticalSlider
                  aria-label="Temperature"
                  className="achivePointSlider"
                  defaultValue={localStorage.getItem("savings_visit_earn") || 0}
                  value={localStorage.getItem("savings_visit_earn") || 0}                
                  onChange={handleSliderChange}
                  valueLabelDisplay="off"               
                  marks={marks2.map((mark, ind) => ({
                    value: mark.value,
                    label: marks3[ind] == 0 ? "" : marks3[ind],
                  }))}
                  orientation="vertical"
                  min={0}
                  // step={100}
                  max={50}
                  // max={marks2[marks2.length-1]?.value}
                  sx={{
                    color: "#E85F34",
                    boxShadow: "0px 0px 0px",
                    borderRadius: "20px",
                    zIndex: "222",
                    position: "relative",
                    height: "368px",                    
                    boxSizing: "border-box",
                    '&.Mui-disabled': {
                      color: '#E85F34', // Custom color for disabled state
                    },
                    "& .MuiSlider-thumb": {
                      height: 26,
                      width: 37,
                      boxShadow: "inherit !important",
                      background: `url(${thumbimg})`,
                      border: "0px solid currentColor",
                      "&:focus, &:hover, &.Mui-active": {
                        boxShadow: "inherit",
                      },
                      ":before": {
                        boxShadow: "0px 0px 0px",
                      },
                    },
                    "& .MuiSlider-track": {
                      width: 6,
                    },
                    "& .MuiSlider-rail": {
                      backgroundColor: "#36276A",
                      opacity: "1",
                      width: 6,
                    },
                    "& .MuiSlider-mark": {
                      backgroundColor: "#36276A",
                      height: 2,
                      width: 14,
                      borderRadius: "20px",
                      "&.MuiSlider-markActive": {
                        backgroundColor: "transparent",
                      },
                    },
                    "& .MuiSlider-markLabel": {
                      color: "#7524CE",
                      textAlign: "center",
                      fontFamily: "Metropolis",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "10px",
                      paddingLeft:"10px"
                    },
                    "& .MuiSlider-markLabel.MuiSlider-markLabelActive": {
                      background: `url(${points30bg})`,
                      color: "#FE8B37",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "106px",
                      backgroundPosition: "-10px !important",
                      height: "52px !important",
                      backgroundPosition: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      backgroundRepeat: "no-repeat",
                      "& .MuiSlider-markLabel.MuiSlider-markLabelActive:nth-child(1)": {
                          display:"none"
                      }
                    },
                  }}
                />
              </div>
            </Box>
          </Grid> 
          {/* <img style={{width:'100%'}} src={require('./vs.png')} /> */}
          <Grid>
            <Box pt={1}>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.50)",
                  textAlign: "center",
                  fontFamily: "Metropolis",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  paddingBottom: "6px",
                  lineHeight: "140%",
                }}
              >
                *Last updated on {localStorage.getItem('formated_date')}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <a href='cashkaro://home'><ButtonComponent title="Start Collecting Points" /></a>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>{" "}
    </Grid>
  );
}
