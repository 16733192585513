export const acceleratedBrands = [
    {
      name: "Flipkart",
      url: "https://www.flipkart.com",
      image: "flipkart"
    },
    {
      name: "Amazon",
      url: "https://www.amazon.com",
      image: "amazon"
    },
    {
      name: "Shopsy",
      url: "https://www.shopsy.in",
      image: "shopsy"
    },
    {
      name: "Mamaearth",
      url: "https://www.mamaearth.in",
      image: "mamaearth"
    },
    {
      name: "Fitspire",
      url: "https://www.fitspire.com",
      image: "fitspire"
    },
    {
      name: "Bombay Shaving Company",
      url: "https://www.bombayshavingcompany.com",
      image: "bombay-shaving-company"
    },
    {
      name: "Medibuddy",
      url: "https://www.medibuddy.in",
      image: "medibuddy"
    },
    {
      name: "BeatO",
      url: "https://www.beato.in",
      image: "beato"
    },
    {
      name: "Koparo",
      url: "https://www.koparoclean.com",
      image: "koparo-clean"
    }
  ];


  export const couponBrands = [
    {
      name: "mCaffeine",
      offer: "Flat 18% off",
      coupon_code: "MCCD15",
      image: "mcaffeine"
    },
    {
      name: "Shyaway",
      offer: "15% off on MoV of ₹1,200",
      coupon_code: "CK15",
      image: "shyaway"
    },
    {
      name: "Truemeds",
      offer: "25% off for new user",
      coupon_code: "CK25",
      image: "truemeds"
    },
    {
      name: "Cadbury",
      offer: "Flat 15% off",
      coupon_code: "JOY15",
      image: "cadbury"
    },
    {
      name: "WeRyze",
      offer: "Flat 10% off",
      coupon_code: "CK10",
      image: "weryze"
    },
    {
      name: "Shyaway",
      offer: "10% off on MoV of ₹499",
      coupon_code: "CK10",
      image: "shyaway"
    },
    {
      name:"MamaEarth",
      offer:"Buy 3 pay for 2",
      coupon_code:"B3P2",
      image:"mamaearth"
    },
    {
      name:"Puma",
      offer:"Flat 7% off across categories",
      coupon_code:"CJPUMA7",
      image:"puma"
    },
    {
      name:"GIVA",
      offer:"Flat 10% off on orders over ₹799",
      coupon_code:"CKBDAY10",
      image:"giva"
    },
    {
      name:"Pattern",
      offer:"Flat 10% off",
      coupon_code:"CK10",
      image:"pattern"
    }
  ];
  

  export const eligibleBrands = [
    {
      name: "Minimalist",
      min_order_value: "₹500",
      image: "minimalist",
      url: "https://www.minimalist.co"
    },
    {
      name: "Puma",
      min_order_value: "₹2,000",
      image: "puma",
      url: "https://in.puma.com"
    },
    {
      name: "Bombay Shaving Company",
      min_order_value: "₹500",
      image: "bombay-shaving-company",
      url: "https://www.bombayshavingcompany.com"
    },
    {
      name: "Foxtale",
      min_order_value: "₹500",
      image: "foxtale",
      url: "https://www.foxtale.com"
    },
    {
      name: "Shopsy",
      min_order_value: "₹1,000",
      image: "shopsy",
      url: "https://www.shopsy.in"
    },
    {
      name: "Libas",
      min_order_value: "₹2,000",
      image: "libas",
      url: "https://www.libas.in"
    },
    {
      name: "MamaEarth",
      min_order_value: "₹500",
      image: "mamaearth",
      url: "https://www.mamaearth.in"
    },
    {
      name: "mCaffeine",
      min_order_value: "₹1,000",
      image: "mcaffeine",
      url: "https://www.mcaffeine.com"
    },
    {
      name: "Nykaa",
      min_order_value: "₹1,500",
      image: "nykaa",
      url: "https://www.nykaa.com"
    },
    {
      name: "Go Noise",
      min_order_value: "₹1,500",
      image: "go-noise",
      url: "https://www.gonoise.com"
    },
    {
      name: "Smytten",
      min_order_value: "₹500",
      image: "smytten",
      url: "https://www.smytten.com"
    },
    {
      name: "Muscleblaze",
      min_order_value: "₹2,500",
      image: "muscleblaze",
      url: "https://www.muscleblaze.com"
    },
    {
      name: "Adidas",
      min_order_value: "₹2,000",
      image: "adidas",
      url: "https://www.adidas.co.in"
    },
    {
      name: "GIVA",
      min_order_value: "₹1,500",
      image: "giva",
      url: "https://www.giva.co"
    },
    {
      name: "Bella Vita",
      min_order_value: "₹500",
      image: "bella-vita",
      url: "https://www.bellavitaorganic.com"
    },
    {
      name: "WeRyze",
      min_order_value: "₹800",
      image: "WeRize",
      url: "https://www.neeman.com"
    },
    {
      name: "Forest Essentials India",
      min_order_value: "₹500",
      image: "forest-essentials-india",
      url: "https://www.forestessentialsindia.com"
    },
    {
      name: "Neeman",
      min_order_value: "₹500",
      image: "neeman",
      url: "https://www.neeman.com"
    }
  ];
  

  export const faqs = [
    {
      question: "What is the validity of the CashKaro VIP Pass?",
      answer:"The pass is valid until the end of the month. For example, if you purchase the pass on 15th Dec, it will be valid until 31st December. Be sure to make the most of your pass during this period!"
    },
    {
      question: "How do I access the increased cashback rates?",
      answer: "To benefit from the increased cashback rates, please ensure you access the brand stores through the dedicated 'Increased Cashback' section provided as part of this campaign. Shopping through other sections of the app will not qualify for the increased rates."
    },
    {
      question: "What happens if I access a brand store from outside the designated section?",
      answer: "Accessing brands from outside the designated 'Increased Cashback' section will result in standard cashback rates. To ensure you receive the increased rates, always shop through the dedicated section."
    },
    {
      question: "How is the extra cashback calculated if I make multiple purchases?",
      answer: "The extra cashback is calculated based on each eligible purchase made through the dedicated section."
    },
    {
      question: "When will I receive the extra perks, like the ₹50 bonus?",
      answer: "The additional bonuses are credited to your account once all qualifying transactions have been confirmed and the cashback has been approved. This process can take time, depending on the retailer's confirmation speed."
    },
    {
      question: "What if I return or cancel a purchase?",
      answer: "Returns or cancellations will affect your eligibility for bonuses. Cancelled or returned transactions will not count towards bonus thresholds."
    },
    {
      question: "Can I transfer my CashKaro VIP Pass to another user?",
      answer: "The CashKaro VIP Pass is non-transferable and is associated with the account of the purchaser only. It cannot be transferred to other users."
    },
    {
      question: "Are there any restrictions on the types of products or brands that qualify for increased cashback?",
      answer: "Certain brands may be excluded from increased cashback offers. Check the detailed terms and conditions in the 'Increased Cashback' section for specific exclusions."
    },
    {
      question: "Can I use other coupons or discounts in conjunction with the increased cashback offers?",
      answer: "Yes, you can combine other coupons or discounts with the increased cashback offers unless otherwise specified."
    },
    {
      question: "Is there a limit to how much increased cashback I can earn with the pass?",
      answer: "There is no cap on the increased cashback you can earn during the validity period of your pass. Shop more to save more!"
    }
  ];
  
  
  
  