import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, IconButton, Paper, Typography, Box, FormHelperText, Stack, Divider, Alert, AlertTitle } from '@mui/material';
// import Topbanner from '../../images/main-banner.png';
import background from '../../images/diwali/bg.png';
import Jhumar from '../../images/diwali/jhumar.png';
import League from '../../images/diwali/league.png';
import Heading from '../../images/diwali/register-heading.png';
import Draw from '../../images/diwali/draw.png';
import HeroSection from '../../images/diwali/new/herosection.png';
import EarnPoints from '../../images/diwali/new/earn.png';
import Assured from '../../images/diwali/new/Frame 1321316774 (2).png';
import AboutPrize from '../../images/diwali/new/image.png';
import Footer from '../../images/diwali/footer.png';
import PastWinner from '../../../src/components/diwali/pastWinner';
import PastWinnerBg from '../../images/diwali/pastwinner-bg.png';
import PastWinnerText from '../../images/diwali/past-winner.png';
import Terms from '../../../src/components/diwali/t&c';
import TermImg from '../../images/diwali/Term-img.png';
import Button from '@mui/material/Button';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CongratulationsPopup from '../../components/diwali/congratulation';
import CongratulationsPopupRepeat from '../../components/diwali/congratulationRepeat';


export default function Signup() {


    const navigate = useNavigate();
    const ck_id = localStorage.getItem('savings_ck_id_dec')

    useEffect(()=>{
        if(ck_id){
            navigate('/diwali-league/index',{replace:true})
            setShowRepeatPopup(true)
            setOpenRepeatPopup(true)
            console.log(openPopup)
        }
    },[])

    
    const handleClosePopup = () => {
        setOpenPopup(false);
        setOpenRepeatPopup(false)
    };


    const [checked, setChecked] = useState(true);
    const [showError, setShowError] = useState(false);
    const [openPopup,setOpenPopup] = useState(false)
    const [openRepeatPopup,setOpenRepeatPopup] = useState(false)
    const [showPopup,setShowPopup] = useState(false)
    const [showRepeatPopup,setShowRepeatPopup] = useState(false)

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setShowError(false); // Reset error when the checkbox is checked
    };

    const handleSubmit =async () => {
        if (!checked) {
            setShowError(true); // Show error if the checkbox is not checked
        } else {
            const params = new URLSearchParams(window.location.search);
            let ck_id = params.get('userid') || params.get('user_id') || params.get('userId') || params.get('ck_id') || params.get('ckid')
            // console.log(ck_id)
            if(ck_id != null && ck_id != "null"){
                try {
                    const response = await fetch(`https://leaderboard-1.earningshub.co/api/user/create_cdl?${ck_id ? `userId=${ck_id}` : ''}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                       
                    });
        
                    const data = await response.json();
        
                    if (data.status === 1) {
                        localStorage.setItem('savings_ck_id_dec', data?.ck_id);
                        setShowPopup(true)
                        setOpenPopup(true)
                    } else {
                        console.log(data.message); // Alert for demonstration
                    }
                } catch (error) {
                    console.error('Error:', error);
                    alert('An error occurred. Please try again.');
                }
            }else{
                navigate('/diwali-league/register');
            }
             // Proceed with submission if checked
        }
    };

    // const handleChange = (event) => {
    //     setChecked(event.target.checked);
    // };

    const scrollToElementt = () => { formReff.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); };
    const formReff = useRef(null);


    return (
        <Grid container sx={{ background: '#fff' }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Grid item xs={12}
                //     sx={{
                //         backgroundImage: `url(${background})`,
                //         backgroundSize: 'cover', // Ensures the image covers the entire container
                //         backgroundPosition: 'center', // Center the image
                //         backgroundRepeat: 'no-repeat', // Prevents image repetition
                //         width: '100%',
                //         height: 'auto', // Adjust height as needed
                //         paddingBottom: '3rem'
                //     }}
                // >
                //     <img src={Jhumar} alt='jhumar' style={{ width: '100%', marginTop: '-6%', position: 'relative', zIndex: '999999' }} />
                //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '74%', marginLeft: '15%' }}>
                //         <img src={League} alt='League' style={{ width: '100%', marginTop: '-46%' }} />
                //     </div>
                //     <img src={Heading} alt='Heading' style={{ width: '100%', paddingLeft: '36px', paddingRight: '36px', marginTop: '-57%' }} />
                ><img src={HeroSection} alt='HeroSection' style={{ width: '100%', }} />


                </Grid>
                <Grid item xs={12}><img src={Assured} alt='Assured' style={{ width: '100%', marginTop: '-6%' }} />
                </Grid>
                <Grid item xs={12}>
                    <img src={EarnPoints} alt='EarnPoints' style={{ width: '100%', marginTop: '-7%' }} />
                </Grid>
                <Grid item xs={12} sx={{ position: 'relative', marginTop: '-60%' }}>
                    <img src={AboutPrize} alt='AboutPrize' style={{ width: '100%', }} />

                </Grid>
                {/* <Grid item xs={12}
                    sx={{
                        backgroundImage: `url(${PastWinnerBg})`,
                        backgroundSize: 'contain', // Ensures the image covers the entire container
                        backgroundPosition: 'center', // Center the image
                        backgroundRepeat: 'repeat-x',
                        width: 'auto',
                        // height: '292px',
                        marginTop: '-49px',
                        zIndex: '11111',
                        position: 'relative',

                    }}

                >
                    <Box px={3.25} sx={{ paddingTop: '34px' }}><img src={PastWinnerText} alt='PastWinnerText' style={{ width: '100%' }} /></Box>

                    <PastWinner />
                </Grid> */}
                <Grid item xs={12} ref={formReff}>
                    <Box px={5} pt={3} pb={4}>
                        <img src={TermImg} alt='termImg' width={'100%'} />
                    </Box>

                    <Terms dynamicCss={{ color: "#000" }} />
                </Grid>

                <Grid item xs={12} mt={3}>

                    <img src={Footer} alt="Footer" style={{ width: '100%', marginBottom: '150px' }} />

                </Grid>
                {!showPopup && !showRepeatPopup && <Grid container sx={{ position: 'relative', zIndex: '999999999', backgroundColor: '#fff' }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} sx={{
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: '#fff', // Set background color explicitly here
                        padding: '16px', // Add padding if needed for better spacing
                        boxShadow: '0 -2px 10px rgba(0,0,0,0.1)', // Optional: Add shadow to give it a "floating" effect
                        }}
                           >
                        <FormControlLabel
                            sx={{
                                // Remove margin from the root element if needed
                                marginRight: '0px !important', // This affects space on the right, update or remove as necessary
                                '& .MuiFormControlLabel-label': {
                                    marginLeft: '0px' // This will reduce space between the checkbox and its label
                                }
                            }}
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    sx={{
                                        color: '#0036DA',
                                        '&.Mui-checked': { color: '#0036DA' },
                                        marginLeft: '0px' // If the checkbox itself has any left margin, this will remove it
                                    }}
                                />
                            }
                        />

                        <span style={{
                            color: '#262626',
                            fontFamily: 'Metropolis',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '16px', /* 133.333% */
                            marginLeft: '0px' // This ensures no left margin for the span text if it's outside the FormControlLabel
                        }}>
                            I agree to the T&Cs of the campaign listed <span style={{color:'blue'}}><a onClick={scrollToElementt}>here</a></span>.
                            {/* <Link href="#" color="primary"> here </Link>. */}
                        </span>
                        {showError && (
                <Typography color="error" sx={{ marginTop: '0px',fontSize:'12px',fontFamily:'Metropolis' }}>
                    You must agree to the terms and conditions to proceed.
                </Typography>
            )}

                        <Box sx={{ marginTop: '1rem' }}>
                            <Button
                                onClick={() => handleSubmit()}
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    backgroundColor: '#0036DA',
                                    borderRadius: '12px',
                                    color: '#fff',
                                    height: '48px',
                                    padding: '16px',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    fontFamily: 'Metropolis',
                                    textTransform: 'none',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: '#0041a3',
                                    },
                                }}
                            >
                                To Get Started, Register
                            </Button>
                        </Box>

                    </Grid>
                </Grid>}

            </Grid>

            {/* {showRepeatPopup && <>
                <CongratulationsPopupRepeat open={openRepeatPopup} onClose={handleClosePopup} />
            </>} */}

            {showPopup && <>
             <CongratulationsPopup open={openPopup} onClose={handleClosePopup} />
            </>}

            <Grid item xs={12} sm={12} md={4} lg={4}>
            </Grid>
        </Grid>

    )
};