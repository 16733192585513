import React, { useRef } from 'react';
import { useState } from 'react';
import Hero from '../../images/diwali/mtm.png';

import { Grid, IconButton, Paper, Typography, Box, FormHelperText, TextField, Stack, Divider, Alert, AlertTitle, Button } from '@mui/material';
import ButtonComponent from '../../components/diwali/buttonBottom';
export default function Higher() {




    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{ backgroundColor: '#1F162E',height:'100vh', }}>
                <Grid item xs={12}>
                    <img src={Hero} alt="Hero" style={{ width: '100%' }} />
                </Grid>
                <Grid sx={{ textAlign: 'center', marginTop: '16px' }} item xs={12}>
                    <Typography sx={{ color: '#fff', fontFamily: 'Metropolis', fontSize: '16px', fontWeight: 700, lineHeight: '140%' }}>Higher the bill better the points</Typography>
                </Grid>
                <Grid sx={{ textAlign: 'center', marginTop: '8px' }} item xs={12}>
                    <Typography sx={{ color: '#fff', fontFamily: 'Metropolis', fontSize: '12px', fontWeight: 500, lineHeight: '16.8px' }}>Spend big, get big! Spend minimum ₹5000 in a <br /> day to get additional points. You can win more <br /> points by spending everyday</Typography>
                </Grid>
                <Grid sx={{ marginTop: '32px', paddingX: '16px', display: 'flex' }} item xs={12}>
                    <Box sx={{ width: '65%', borderRadius: '8px 0px 0px 8px', height: '78px', display: 'flex', alignItems: 'center', background: 'linear-gradient(137deg, #5F51B4 4.31%, #2E234E 97.22%)', padding: '16px' }}>
                        <Typography sx={{ color: '#fff', fontFamily: 'Metropolis', fontSize: '14px', fontWeight: 600, lineHeight: '18px' }}>Min. Spend ₹10,000</Typography>
                    </Box>
                    <Box sx={{ width: '38%', borderRadius: '8px 8px 8px 8px', height: '78px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'linear-gradient(108deg, #FFE12F 9.19%, #FFBD59 100%)', marginLeft: '-10px', padding: '16px', textAlign: 'center' }}>
                        <Typography sx={{ color: '#000', fontFamily: 'Metropolis', fontSize: '12px', fontWeight: 500, lineHeight: '16.8px' }}>Bonus <br /> <Typography sx={{ color: '#301796', fontFamily: 'Metropolis', fontSize: '16.235px', fontWeight: 800, lineHeight: '22.729px', letterSpacing: '-0.487px' }}>200 Points</Typography></Typography>
                    </Box>
                </Grid>
                <Grid sx={{ marginTop: '16px', paddingX: '16px', display: 'flex' }} item xs={12}>
                    <Box sx={{ width: '65%', borderRadius: '8px 0px 0px 8px', height: '78px', display: 'flex', alignItems: 'center', background: 'linear-gradient(137deg, #5F51B4 4.31%, #2E234E 97.22%)', padding: '16px' }}>
                        <Typography sx={{ color: '#fff', fontFamily: 'Metropolis', fontSize: '14px', fontWeight: 600, lineHeight: '18px' }}>Min. Spend ₹7,500</Typography>
                    </Box>
                    <Box sx={{ width: '38%', borderRadius: '8px 8px 8px 8px', height: '78px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'linear-gradient(108deg, #FFE12F 9.19%, #FFBD59 100%)', marginLeft: '-10px', padding: '16px', textAlign: 'center' }}>
                        <Typography sx={{ color: '#000', fontFamily: 'Metropolis', fontSize: '12px', fontWeight: 500, lineHeight: '16.8px' }}>Bonus <br /> <Typography sx={{ color: '#301796', fontFamily: 'Metropolis', fontSize: '16.235px', fontWeight: 800, lineHeight: '22.729px', letterSpacing: '-0.487px' }}>150 Points</Typography></Typography>
                    </Box>
                </Grid>
                <Grid sx={{ marginTop: '16px', paddingX: '16px', display: 'flex' }} item xs={12}>
                    <Box sx={{ width: '65%', borderRadius: '8px 0px 0px 8px', height: '78px', display: 'flex', alignItems: 'center', background: 'linear-gradient(137deg, #5F51B4 4.31%, #2E234E 97.22%)', padding: '16px' }}>
                        <Typography sx={{ color: '#fff', fontFamily: 'Metropolis', fontSize: '14px', fontWeight: 600, lineHeight: '18px' }}>Min. Spend ₹5,000</Typography>
                    </Box>
                    <Box sx={{ width: '38%', borderRadius: '8px 8px 8px 8px', height: '78px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'linear-gradient(108deg, #FFE12F 9.19%, #FFBD59 100%)', marginLeft: '-10px', padding: '16px', textAlign: 'center' }}>
                        <Typography sx={{ color: '#000', fontFamily: 'Metropolis', fontSize: '12px', fontWeight: 500, lineHeight: '16.8px' }}>Bonus <br /> <Typography sx={{ color: '#301796', fontFamily: 'Metropolis', fontSize: '16.235px', fontWeight: 800, lineHeight: '22.729px', letterSpacing: '-0.487px' }}>100 Points</Typography></Typography>
                    </Box>
                </Grid>
                <Box sx={{ textAlign: 'center', marginTop: '24px',paddingBottom:'30%'}}>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.80)', fontFamily: 'Metropolis', fontSize: '10px', fontWeight: 500, lineHeight: '14px' }}>*Spending for the day is calculated at 11:59 PM daily</Typography>
                </Box>
                
                <a href='cashkaro://home'><ButtonComponent title="Shop Now"/></a>
            </Grid>


            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>

        </Grid>
    )
};