import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { acceleratedBrands, couponBrands } from "../acceleratedCashbackData";
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';


const Toast = ({ message, show }) => {
  return <div className={`toast ${show ? "show" : ""}`}>{message}</div>;
};

export default function ActivatePass(props) {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const inputRef = useRef();
  const [mobile, setMobile] = useState('');
  const navigate = useNavigate()
  const [loading,setLoading] = useState(false)



  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = async () =>{
    setLoading(true);
    try {
        let response = await fetch(`${process.env.REACT_APP_SITE_URL_VP}/api/user/create_vp`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({contact:mobile})
        })
        response = await response.json();
        console.log(response);
        localStorage.setItem('vp_ck_id_dec',response.ck_id)
        navigate('/vip-pass-nov-new-user/activated') 
    } catch (error) {
        console.log(error)
    }
    setLoading(false)
  }

  const handleMobileChange = (event) => {
    const { value } = event.target;
    // Allow only digits and max 10 characters
    if (/^\d{0,10}$/.test(value)) {
      setMobile(value);
    }
  };

  const isMobileValid = () => {
    // Simple regex to validate 10 digit mobile number
    return /^\d{10}$/.test(mobile);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        lg={4}
        sx={{
          position: "relative",
          backgroundColor: "#fff",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
          backgroundColor: "#222335",
        }}
      >
        <Box sx={{ width: "100%", margin: "0 !important" }}>
          <Box
            onClick={props.closefunc}
            sx={{ display: "flex", justifyContent: "center", marginTop: "8px" }}
          >
            <Box
              sx={{
                width: "54px",
                borderTop: "5px solid #D9D9D9",
                borderRadius: "30px",
              }}
            ></Box>
          </Box>
          <Grid
            mx={2}
            pb={1}
            pt={1}
            sx={{ width: "100%", margin: "0 !important" }}
          >
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                paddingY: "0.5rem",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#222335",
              }}
            >
              <Box sx={{ marginBottom: "0.5rem" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    color: "#fff",
                    fontFamily:"Metropolis"
                  }}
                >
                  Activate Pass
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Divider fullWidth />
            </Box>
            <Box sx={{ width: "100%", backgroundColor: "#030418" }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Toast message={toastMessage} show={showToast} />
              </Box>
              <Box p={4} sx={{ textAlign: 'center' }}>

                <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#fff' }}>
                  Please enter your registered CashKaro <br /> mobile number to activate your pass
                </Typography>

                <TextField
                  type='tel'
                  label="Enter Mobile Number"
                  fullWidth
                  // inputRef={input => input && input.focus()}
                  value={mobile}
                  onChange={handleMobileChange}
                  inputProps={{ maxLength: 10 }}
                  sx={{
                    borderRadius: '20px',
                    marginTop: '24px',
                    marginBottom: '154px',
                    '& .MuiInputLabel-root': {
                      color: '#6A6C82', // Change the color to your desired color
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#6A6C82', // Change the color when focused
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#6A6C82', // Remove blue border when focused
                    },
                    '& .MuiOutlinedInput-input': {
                      color: '#FFFFFF', // Text color
                      fontWeight: 700,
                      fontSize: '16px'
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#6A6C82', // Outline color
                        borderRadius: '12px', // Border radius
                      },
                    }
                  }}
                />
              </Box>
            </Box>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container sx={{ zIndex: 1, background: '#222335', postion: 'relative', }}>
                <Grid item xs={12} sm={12} md={4} lg={4} sx={{ position: 'fixed', zIndex: 1, bottom: 0, width: '100%' }}>
                  <div style={{ height: '24px', width: '100%', background: '#2E5744', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography sx={{ color: '#fff', fontSize: '12px', fontWeight: 600 }}>This pass is free. No payment needed!</Typography>
                  </div>
                  <div style={{ backgroundColor: '#222335', textAlign: 'center', padding: '20px' }} >
                    <LoadingButton
                    loading={loading}
                    onClick={handleSubmit}
                      disabled={!isMobileValid()}
                      sx={{
                        height:'48px',
                        width: '100%',
                        borderRadius: '0.5rem',
                        background: 'linear-gradient(302.14deg, #C68B4A 1.32%, #FFEBC9 91.59%)',
                        color: '#000', textAlign: 'center', fontSize: '14px', fontWeight: 700, paddingY: '0.9rem',
                        textTransform: 'capitalize',
                        "&.Mui-disabled": {
                          background: "#3F4052",
                          color: '#67687A'
                        }
                      }}
                    >
                      {loading ? "" : <Typography sx={{fontSize: '14px', fontWeight: 700}}>Activate Pass</Typography>}
                    </LoadingButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
    </Grid>
  );
}
