import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Trophy from '../../images/diwali/trophy.png';
import { useNavigate } from 'react-router-dom';
import React from "react";



const BottomSheet1 = () => {

    const navigate = useNavigate()
    return (

        <Grid container>

            <Grid item xs={12} sm={4} md={4} lg={4}></Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}

                sx={{ position: "relative", zIndex: '999999999999999', backgroundColor: "#fff", borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem", backgroundColor: "#fff", }}

            >

                <Box

                    sx={{ width: "100%", margin: "0 !important", height: "60vh", overflow: "auto", }}

                >

                    <Grid

                        mx={2}

                        pb={1}

                        pt={1}

                        sx={{ width: "100%", margin: "0 !important" }}

                    >

                        <Box

                            id="header"

                            sx={{

                                width: "100%",

                                textAlign: "center",

                                paddingY: "0.5rem",

                                display: "flex",

                                justifyContent: "center",

                                backgroundColor: "#fff",

                                position: "sticky",

                                top: 0,

                                zIndex: 1,

                            }}

                        >

                            <Box sx={{ marginBottom: "0.5rem" }}>

                                <Box

                                    sx={{

                                        display: "flex",

                                        justifyContent: "center",

                                        marginBottom: "8px",

                                    }}

                                >

                                    <Box

                                        sx={{

                                            width: "54px",

                                            borderTop: "5px solid #D9D9D9",

                                            borderRadius: "30px",

                                        }}

                                    ></Box>

                                </Box>

                            </Box>

                        </Box>

                        <Box

                            sx={{

                                width: "100%",

                                backgroundColor: "#fff",

                                overflowX: "hidden",

                                marginTop: '24px'



                            }}

                        >

                            <Box sx={{ display: "flex", justifyContent: "center" }}>

                                <img style={{ height: '120px', filter: 'grayscale(100%)' }} src={Trophy} />

                            </Box>

                        </Box>

                        <Box sx={{ textAlign: 'center', marginTop: '24px' }}>

                            <Typography sx={{ color: '#391278', fontFamily: 'Metropolis', fontSize: '14px', fontWeight: 700, lineHeight: '20px' }}>Uh Oh!</Typography>

                            <Typography sx={{ color: '#262626', fontFamily: 'Metropolis', fontSize: '14px', fontWeight: 600, lineHeight: '20px', marginTop: '8px' }}>You have 0 points <br /> Collect points & get to Top 500</Typography>

                        </Box>

                        <Box   mx={2} sx={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>

                            <Button

                                fullWidth

                                variant="contained"
                              

                                onClick={()=>navigate('/diwali-league/play-point')}

                                sx={{

                                    backgroundColor: '#0036DA', // Button color

                                    color: '#fff', // Text color inside the button

                                    borderRadius: '8px',

                                    padding: '12px 16px',

                                    textTransform: 'none', // Disable uppercasing

                                    width: '100%',

                                    maxWidth: '400px', // Limit button width

                                    fontSize: '16px',

                                    fontWeight: 500,

                                    '&:hover': {

                                        backgroundColor: '#0041A3', // Hover state for button

                                    },

                                }}

                            >

                                Start Collecting Points

                            </Button>

                        </Box>

                    </Grid>

                </Box>

            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}></Grid>

        </Grid>

    );

};



export default BottomSheet1;