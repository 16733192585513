import React, { useState } from "react";
import { Grid, Typography, Box, Slider } from "@mui/material";

import discoverbrand from "../../images/diwali/discoverbrand.png";
import samsung from "../../images/diwali/samsung.png";
import booking from "../../images/diwali/booking.png";
import boat from "../../images/diwali/boat.png";
import Nyka from "../../images/diwali/amazon (1).png";
import Clove from "../../images/diwali/new/clovee.png";
import trumeds from "../../images/diwali/trumeds.png";
import adidas from "../../images/diwali/adidas.png";
import xyxx from "../../images/diwali/xyxx.png";
import Strch from "../../images/diwali/new/strchh.png";
// import dreamco from "../../images/diwali/dreamco.png";
import SSbeauty from "../../images/diwali/amazon.png";
import mcaffine from "../../images/diwali/mcaffine.png";
import ssbeauty from "../../images/diwali/ssbeauty.png";
import forest from "../../images/diwali/forest.png";
import getmorepointimg from "../../images/diwali/getmorepointimg.png";
import kamaayurveda from '../../images/diwali/kamaayurveda.png'

import thumbimg from "../../images/diwali/thumbimg.png";
import { Link } from "react-router-dom";

export default function DiscoverBrand() {
  const [isLoggedIn, setIsLoggedIn] = useState(true); 
  const [activeLink, setActiveLink] = useState(null);
  const segment = localStorage.getItem('segment')

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const data = [
    {
      imgSrc: samsung,
      title: "Samsung",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/samsung-offers"
        : "cashkaro://stores/samsung-offers",
    },
    {
      imgSrc: booking,
      title: "Booking.com",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/booking"
        : "cashkaro://stores/booking",
    },
    {
      imgSrc: boat,
      title: "BoAt",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/boat-coupon"
        : "cashkaro://stores/boat-coupon",
    },
    {
      imgSrc: trumeds,
      title: "Trumeds",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/truemeds-coupon-code"
        : "cashkaro://stores/truemeds-coupon-code",
    },
    {
      imgSrc: adidas,
      title: "Adidas",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/adidas"
        : "cashkaro://stores/adidas",
    },
    {
      imgSrc: xyxx,
      title: "XYXX Crew",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/xyxx-discount-code"
        : "cashkaro://stores/xyxx-discount-code",
    },
    {
      imgSrc: Nyka,
      title: "Nykaa",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/nykaa"
        : "cashkaro://stores/nykaa",
    },
    {
      imgSrc: mcaffine,
      title: "mCaffiene",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/mcaffeine-coupons"
        : "cashkaro://stores/mcaffeine-coupons",
    },
    {
      imgSrc: Strch,
      title: "Strch",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/strch-coupons"
        : "cashkaro://stores/strch-coupons",
    },
    {
      imgSrc: Clove,
      title: "Clove",
      link: segment == 1 || segment == 2 || segment == 4
        ? "cashkaro://stores/clove-oral-care-coupons"
        : "cashkaro://stores/clove-oral-care-coupons",
    },
  ];
  return (
    <Grid container sx={{ background: "#fff" }}>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Grid
          sx={{
            background: "#1F162E",
            width: "100%",
          }}
        >
          <Grid pb={2}>
            <img src={discoverbrand} style={{ width: "100%" }} />
          </Grid>
          <Grid item sx={12} pr={3} pl={3} pb={2}>
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Metropolis",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "140%",
                textTransform: "capitalize",
                marginBottom: "8px",
              }}
            >
              Discover brands
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Metropolis",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "140%",
                color: "#fff",
              }}
            >
             <strong> Click on the logos</strong> to visit brand's app or website. Get 10 points
              for every unique brand you visit via CashKaro.
            </Typography>
          </Grid>
          <Grid
            container
            spacing={0}
            sx={{ justifyContent: "center" }}
            pb={5}
            pl={2}
            pr={2}
          >
            {data.map((item, index) => (
              <Grid item xs={4} key={index}>
                <a
                  href={item.link}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  onClick={() => handleLinkClick(item.link)}
                >
                  <img src={item.imgSrc} style={{ width: "100%" }} />
                  <Typography
                    sx={{
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Metropolis",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "120%",
                      position: "relative",
                      top: "-10px",
                      textDecoration: "none",
                    }}
                  >
                    {item.title}
                  </Typography>
                </a>
              </Grid>
            ))}
          </Grid>
          <Grid sx={12} pl={2} pr={2} pb={2.5}>
            <img src={getmorepointimg} style={{ width: "100%" }} />
          </Grid>
          <Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0 24px 8px",
              }}
            >
              <Typography
                sx={{
                  color: "#FE8B37",
                  textAlign: "center",
                  fontFamily: "Metropolis",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "10px",
                  width: "33.3%",
                }}
              >
                + 30 points
              </Typography>
              <Typography
                sx={{
                  color: "#FE8B37",
                  textAlign: "right",
                  fontFamily: "Metropolis",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "10px",
                  width: "33.3%",
                }}
              >
                + 60 points
              </Typography>
            </Box>
            <Box sx={{
              padding:"0 32px",
              position:"relative"
            }}
            
            >
      
              <Slider
                aria-label="Temperature"
                disabled
                // getAriaValueText={valuetext}
                valueLabelDisplay="off"
                defaultValue={localStorage.getItem('savings_brand_discovery') || 0}
                shiftStep={30}
                // orientation="vertical"
                step={5}
                marks
                min={0}
                max={10}
                sx={{
                  color: "#E85F34", // Change the color here
                  padding: "4px !important",
                  boxShadow: "0px 0px 0px",
                  borderRadius: "20px",
                  marginBottom: "6px",
                  zIndex: "222",
                  position: "relative",
                  height: 8, // Change the height here
                  '&.Mui-disabled': {
                    color: '#E85F34', // Custom color for disabled state
                  },
                  "& .MuiSlider-thumb": {
                    height: 26,
                    boxShadow: "0px 0px 0px",
                    width: 37,
                    boxShadow: "inherit !important",
                    background: `url(${thumbimg})`, // Change thumb color
                    border: "0px solid currentColor", // Add border if needed
                    "&:focus, &:hover, &.Mui-active": {
                      boxShadow: "inherit",
                    },
                    ":before": {
                      boxShadow: "0px 0px 0px",
                    },
                  },
                  "& .MuiSlider-track": {
                    height: 8, // Change track height
                  },
                  "& .MuiSlider-rail": {
                    height: 8, // Change rail height
                    backgroundColor: "#36276A", // Rail color
                    opacity: "1",
                  },
                  "& .MuiSlider-mark": {
                    backgroundColor: "#36276A", // Mark color
                    height: 12, // Mark height
                    width: 2, // Mark width
                    borderRadius: "20px",
                    "&.MuiSlider-markActive": {
                      backgroundColor: "transparent", // Active mark color
                    },
                  },
                }}
              />
              {/* <Box sx={{
              width: "60.314px",
              height: "8px",
              flexShrink: 0,
              borderRadius: "36.188px",
              background: "#371D0C",
              filter: "blur(2.507853388786316px)",
              margin:"0 auto",
              left:"0",
              right:"0",
              bottom:"11px",
              position:"absolute",
              zIndex:"1"
            }}>

            </Box> */}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 24px 32px",
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Metropolis",
                  fontSize: "8px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "10px",
                }}
              >
                0 Brands
              </Typography>
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Metropolis",
                  fontSize: "8px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "10px",
                }}
              >
                5 Brands
              </Typography>
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Metropolis",
                  fontSize: "8px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "10px",
                }}
              >
                10 Brands
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.50)",
                  textAlign: "center",
                  fontFamily: "Metropolis",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "140%",
                  paddingBottom: "80px",
                }}
              >
                *Last updated on {localStorage.getItem('formated_date')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>{" "}
    </Grid>
  );
}
