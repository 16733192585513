import React from 'react';
import { Box, Typography } from '@mui/material';

export default function MilestoneProgressBar() {
  const milestones = [
    { points: 0, reward: '', isCurrent: true },
    { points: 700, reward: '+ 30 points', isCurrent: false },
    { points: 1000, reward: '+ 30 points', isCurrent: false },
    { points: 1400, reward: '+ 30 points', isCurrent: false },
    { points: 1800, reward: '+ 30 points', isCurrent: false },
    { points: 2100, reward: '+ 30 points', isCurrent: false },
    { points: 2800, reward: '+ 30 points', isCurrent: false },
  ];

  const currentPoints = 3000; // Example: current progress point

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px',
        background: '#1e0330',
        borderRadius: '12px',
        color: '#fff',
        width: '320px',
        margin: 'auto',
        position: 'relative',
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: '8px', fontWeight: 'bold' }}>
        Achieve Point Milestones
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: '24px', opacity: 0.8 }}>
        Unlock extra points at every milestone
      </Typography>

      <Box sx={{ position: 'relative', height: '300px', marginBottom: '24px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {milestones.map((milestone, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ color: '#b59fcc', width: '80px', textAlign: 'right' }}>
              {milestone.points} Points
            </Typography>
            <Box
              sx={{
                width: '2px',
                height: index === milestones.length - 1 ? '20px' : '0px',
                backgroundColor: '#7e52a0',
                marginX: '8px',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: milestone.isCurrent ? '#fff' : '#7e52a0',
                  position: 'absolute',
                  top: '-5px',
                  left: '-4px',
                  zIndex: 1,
                }}
              />
              {milestone.isCurrent && (
                <Box
                  sx={{
                    width: '2px',
                    height: '100%',
                    backgroundColor: '#fff',
                    position: 'absolute',
                    left: '50%',
                    top: '0',
                    transform: 'translateX(-50%)',
                  }}
                />
              )}
            </Box>
            {milestone.reward && (
              <Typography variant="body2" sx={{ color: '#bf85f1', width: '80px' }}>
                {milestone.reward}
              </Typography>
            )}
          </Box>
        ))}
      </Box>

      <Typography variant="body2" sx={{ color: '#bf85f1', fontWeight: 'bold' }}>
        All points claimed
      </Typography>

      <Typography variant="caption" sx={{ marginTop: 'auto', opacity: 0.6 }}>
        *Last updated on {localStorage.getItem('formated_date')}
      </Typography>
    </Box>
  );
}
