import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CongratulationsPopup from '../../components/diwali/congratulation';
import CongratulationsPopupRepeat from '../../components/diwali/congratulationRepeat';
import background from '../../images/diwali/register-bg.png';
import Avator from '../../images/diwali/avator.png';

export default function Signup() {
    const navigate = useNavigate();
    const [openPopup, setOpenPopup] = useState(false);
    const [mobile, setMobile] = useState('');
    const [isRepeat, setIsRepeat] = useState('');
    const [error, setError] = useState('');

    // useEffect(() => {
    //     // Check if 'diwali_ck_id' exists in localStorage
    //     const ckId = localStorage.getItem('diwali_ck_id');
    //     setIsRepeat(ckId !== null);
    // }, []);

    useEffect(() => {
        // Scroll to top
        window.scrollTo(0, 0);
    
        // Disable scrolling after scrolling to the top
        document.body.classList.add('no-scroll');
        
        // Check if 'diwali_ck_id' exists in localStorage
        const ckId = localStorage.getItem('savings_ck_id_dec');
        if (ckId !== null) {
            setIsRepeat('repeat');
        } else {
            setIsRepeat('no-repeat');
        }
    
        // Cleanup function to remove the class
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);
    

    console.log(isRepeat)

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleButtonClick = async () => {
        // Check if mobile number is not filled
        if (!mobile) {
            setError('Please enter your mobile number.');
            return; // Stop further execution
        }
        // Validate mobile number format (assuming 10-digit number for example)
        if (!/^\d{10}$/.test(mobile)) {
            setError('Please enter a valid 10-digit mobile number.');
            return;
        }
        const params = new URLSearchParams(window.location.search);
        const ck_id = params.get('ck_id');

        try {
            const response = await fetch(`https://leaderboard-1.earningshub.co/api/user/create_cdl?${ck_id ? `userId=${ck_id}` : ''}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    contact: mobile,
                }),
            });

            const data = await response.json();

            if (data.status === 1) {
                localStorage.setItem('savings_ck_id_dec', data?.ck_id);
                setOpenPopup(true); // Show the popup
            } else {
                setError(data.message); // Alert for demonstration
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <Grid container sx={{ background: '#fff' }}>
            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Grid item xs={12} sx={{
                    // backgroundImage: `url(${background})`,
                    // backgroundSize: 'cover',
                    background:'radial-gradient(84.15% 55.06% at 50% -3.25%, #8132BD 0%, #220744 100%)',
                    // backgroundPosition: 'center',
                    // backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: 'auto',
                    paddingBottom: '3rem',
                }}>
                    <Grid pt={5} pb={3}>
                        <Box
                            sx={{
                                borderRadius: '114px',
                                border: '1.075px solid #FFF',
                                background: 'linear-gradient(207deg, #9325C7 0%, #4A0D87 26.93%, #2E0B51 55.79%, #1E0835 97.57%)',
                                boxShadow: '-2.962px 1.974px 3.949px 0px rgba(0, 0, 0, 0.80) inset',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '114px',
                                height: '114px',
                                margin: '0 auto',
                            }}
                        >
                            <img src={Avator} alt='Avator' style={{ width: '100%', borderRadius: '50%' }} />
                        </Box>
                        <Box px={3.5} pt={2.5} pb={2.6}>
                            <Typography
                                sx={{
                                    color:'#FFD60E',
                                    textAlign: 'center',
                                    fontFamily: '"Montserrat Alternates"',
                                    fontSize: '24px',
                                    fontWeight: 900,
                                    lineHeight: '130%',
                                }}
                            >
                                Help us create a <br/> profile for you!
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Box px={3} pt={5} sx={{
                    height: '75vh',
                    maxHeight: '700px',
                    backgroundColor: '#1D1523',
                    borderTopLeftRadius: '24px',
                    borderTopRightRadius: '24px',
                    marginTop: '-12%',
                }}>
                    <Typography sx={{
                        color: '#fff',
                        fontFamily: 'Metropolis',
                        fontSize: '0.875rem',
                        fontWeight: "600",
                        lineHeight: '20px',
                    }}>
                        Enter CashKaro Registered Mobile Number
                    </Typography>
                    <Grid item xs={12}>
                        <TextField
                        type="tel"
                            fullWidth
                            value={mobile}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,10}$/.test(value)) {
                                    setMobile(value);
                                    setError(''); // Clear error on valid change
                                } else {
                                    setError('Mobile number must be 10 digits');
                                }
                            }}
                            variant="outlined"
                            placeholder="98881-52312"
                            error={Boolean(error)}
                            helperText={error}
                            FormHelperTextProps={{ sx: { color: 'red', marginTop: '8px' } }}
                            InputProps={{
                                style: {
                                    color: '#FFF',
                                    borderRadius: '8px',
                                    backgroundColor: '#2E2438',
                                    border: '1px solid #928AA0',
                                    marginTop: '1rem',
                                    height: '48px !important',
                                },
                            }}
                        />
                    </Grid>
                </Box>

                <Grid container sx={{ position: 'relative' }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} sx={{
                        position: 'fixed',
                        bottom: 16,
                        width: '100%',
                        boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
                    }}>
                        <Box sx={{ marginX: '1rem', marginTop: '1rem' }}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handleButtonClick}
                                sx={{
                                    backgroundColor: '#FFC600',
                                    color: '#212121',
                                    borderRadius: '8px',
                                    padding: '12px 16px',
                                    textTransform: 'none',
                                    width: '100%',
                                    maxWidth: '400px',
                                    fontSize: '16px',
                                    fontFamily: 'Metropolis',
                                    fontWeight: 600,
                                    '&:hover': {
                                        backgroundColor: '#FFC600',
                                    },
                                }}
                            >
                                Register
                            </Button>
                            {openPopup && (
                                <>
                                    {/* {isRepeat === "repeat" && <CongratulationsPopupRepeat open={openPopup} onClose={handleClosePopup} />} */}
                                    {isRepeat === "no-repeat" && <CongratulationsPopup open={openPopup} onClose={handleClosePopup} />}
                                </>
                            )}


                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
        </Grid>
    );
}
