import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";

import shopcollect from "../../images/diwali/shopcollect.png";
import logoimg from "../../images/diwali/logoimg.png";
import logoimg2 from "../../images/diwali/logoimg2.png";
import logoimg3 from "../../images/diwali/logoimg3.png";
import { Link } from "react-router-dom";



export default function ShopAndCollect() {

  const [activeLink, setActiveLink] = useState(null);
  const segment = localStorage.getItem('segment')

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

const offers = [
  {
    id: 1,
    points: "+ 100 Points",
    image: logoimg, 
    text: "Shop via CashKaro on Amazon, Flipkart, Myntra or AJIO",
    buttonText:"Shop Now",
    link: segment == 1 || segment == 2 || segment == 4
    ? "cashkaro://products/exclude/most-popular-brands"
    : "cashkaro://products/exclude/most-popular-brands-new",    
  },
  {
    id: 2,
    points: "+ 400 Points",
    image: logoimg2, 
    text: "Apply for any Credit Card via CashKaro",
     buttonText:"Apply Now",
     link: segment == 1 || segment == 2 || segment == 4
     ? "cashkaro://products/home-categories-exclusive/banking-finance-offers"
     : "cashkaro://products/home-categories-exclusive/banking-finance-offers",
  },
  {
    id: 3,
    points: "+ 200 Points",
    image: logoimg3, 
    text: "Shop via CashKaro on any other brand ",
     buttonText:"Shop Now",
     link: segment == 1 || segment == 2 || segment == 4
     ? "cashkaro://home"
     : "cashkaro://home",
  },
];
  return (
    <Grid container sx={{ background: "#fff" }}>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Grid
          sx={{
            background: "#1F162E",
            width: "100%",
            height:"100vh"
          }}
        >
          <Grid pb={2}>
            <img src={shopcollect} style={{ width: "100%" }} />
          </Grid>
          <Grid item sx={12} pr={3} pl={3} pb={2}>
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Metropolis",
                fontSize: "16px",
                border: "0",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "140%",
                textTransform: "capitalize",
                marginBottom: "8px",
              }}
            >
              Shop and Collect
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Metropolis",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "140%",
                color: "#fff",
              }}
            >
              Collect points by shopping on any brands via Cashkaro, you get
              different points for every shopping
            </Typography>
          </Grid>
          <Grid
            container
            spacing={0}
            sx={{ justifyContent: "center" }}
            pb={3}
            pl={2}
            pr={2}
          >
            {offers.map((offer) => (
              <Grid
                key={offer.id}
                container
                sx={{
                  borderRadius: "12px",
                  background:
                    "linear-gradient(137deg, #5F51B4 4.31%, #2E234E 97.22%)",
                  padding: "16px",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                }}
              >
                <Grid sx={{ width: "55%" }}>
                  <img src={offer.image} alt="Offer Logo" style={{width:"85%"}} />
                  <Typography
                    sx={{
                      color: "#FFF",
                      fontFamily: "Metropolis",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "13px",
                      paddingTop: "10px",
                      paddingRight:"5px"
                    }}
                  >
                    {offer.text}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "max-content",
                    padding: "4px 2px 0 0",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFD833",
                      textAlign: "center",
                      fontFamily: "Metropolis",
                      fontSize: "16.235px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "140%",
                    }}
                  >
                    {offer.points}
                  </Typography>
                  <Grid style={{ position: "relative" }}>
                    <Grid
                      style={{
                        borderRadius: "4.665px",
                        background: "#FFF",
                        filter: "blur(8px)",
                        display: "inline-flex",
                        height: "27.987px",
                        padding: "9.329px",
                        position: "absolute",
                        top: "12px",
                        left: "0",
                        right: "0",
                        width:"68px",
                        margin:"0 auto"
                      }}
                    ></Grid>
                     <a
                  href={offer.link}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  onClick={() => handleLinkClick(offer.link)}
                > <Button
                      style={{
                        display: "inline-flex",
                        height: "33px",
                        padding: "11px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5.5px",
                        flexShrink: 0,
                        borderRadius: "5.5px",
                        background: "#FFF",
                        color: "#231043",
                        textAlign: "center",
                        fontFamily: "Metropolis",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        marginTop: "10px",
                        letterSpacing:"0",
                        textTransform:"capitalize"
                      }}
                    >
                      {offer.buttonText}
                    </Button>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.80)",
                  textAlign: "center",
                  fontFamily: "Metropolis",
                  fontSize: "10px",
                  fontStyle: "normal",
                  paddingX:'8px',
                  fontWeight: 500,
                  lineHeight: "140%",
                }}
              >
                *No points for Swiggy, Recharges, Bill payments, Amazon Pay &
                brands with no Cashback.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
    </Grid>
  );
}
