export const namesArray = [
    "Aarav", "Advait", "Akash", "Arjun", "Bharat", "Dev", "Dhruv", "Eshaan", "Gaurav", "Harsh", "Ishan", 
    "Jay", "Kabir", "Kartik", "Lakshay", "Manish", "Mohan", "Neeraj", "Omkar", "Pranav", "Raghav", 
    "Rahul", "Rajat", "Rohit", "Sagar", "Sanjay", "Shaurya", "Siddharth", "Tarun", "Uday", "Varun", 
    "Vikram", "Yash", "Zaid", "Abhinav", "Aditya", "Ankit", "Anuj", "Deepak", "Hitesh", "Kunal", 
    "Nitin", "Rakesh", "Sandeep", "Saurabh", "Sumit", "Vikas", "Vijay", "Vivek", "Yuvraj", "Aditi", 
    "Ananya", "Anika", "Avni", "Bhavya", "Charvi", "Deepika", "Divya", "Esha", "Gauri", "Hina", 
    "Ishita", "Jaya", "Jyoti", "Kavya", "Kritika", "Lavanya", "Meera", "Naina", "Neha", "Pallavi", 
    "Pooja", "Priya", "Radhika", "Rhea", "Riya", "Sakshi", "Shalini", "Sneha", "Sonal", "Tanvi", 
    "Trisha", "Urvashi", "Varsha", "Vidya", "Yamini", "Zoya", "Anusha", "Chitra", "Geeta", "Kalpana", 
    "Lata", "Maya", "Nidhi", "Preeti", "Shruti", "Shreya", "Swati", "Vandana", "Veena"
  ];
  

  export function getRandomName() {
    const randomIndex = Math.floor(Math.random() * namesArray.length);
    return namesArray[randomIndex];
  }